import React, { useEffect } from "react";
import UserForm from "./UserForm";
import "../styles/Login.css"; // Import CSS for styling
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {

    useEffect(() => {
        // Push a new history state to detect back press
        window.history.pushState(null, "", window.location.href);

        const handleBackButton = () => {
            window.location.replace("https://www.google.com"); // Use replace instead of href
        };

        window.addEventListener("popstate", handleBackButton);

        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, []);

    return (
        <div className="home-container">
            <div className="home-content">
                <h4 style={{ fontFamily: "Bauhaus2" }}>Welcome to Course Plan</h4>
                <h5 style={{ fontFamily: "Bauhaus2" }}>Join us today and start managing your courses efficiently.</h5>
                <UserForm isLogin={true} />
                <div style={{ fontSize: "14px", fontFamily: "Bauhaus2", marginTop: "5px" }}>
                    By signing in, you agree to our{" "}
                    <a href="/course-plan/privacy-policy" style={{ fontFamily: "Bauhaus2", color: "#007bff" }}>
                        Privacy Policy
                    </a>.
                </div>
            </div>
        </div>
    );
};

export default Login;
