import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { getAllCourses } from '../../../api';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import '../styles/CourseList.css'; // Import CSS for styling
import { useNavigate } from 'react-router-dom';
import { BaseUrl } from '../../../constants';

const CourseList = ({ }) => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, "", window.location.href);
        };
    }, []);
    const fetchCourses = useCallback(async () => {
        try {
            setLoading(true);
            const response = await getAllCourses();
            setCourses(response);
        } catch (error) {
            console.error("Error fetching courses", error);
        } finally {
            setLoading(false);
        }
    }, []);
    useEffect(() => {
        fetchCourses();
    }, [fetchCourses]);

    const deleteUser = async () => {
        setLoading(true)
        let userId = localStorage.getItem('userId')
        let token = localStorage.getItem('token')
        if (!window.confirm("Are you sure you want to delete User Data?")) {
            setLoading(false)
            return;
        }
        try {
            let config = {
                method: 'delete',
                maxBodyLength: Infinity,
                url: BaseUrl + '/api/users/' + userId,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            };
            axios.request(config)
                .then((response) => {
                    setLoading(false)
                    alert("User account and related data deleted successfully");
                    Logout()
                })
                .catch((error) => {
                    setLoading(false)
                    alert(JSON.stringify(error));
                    console.log(error);
                });

        } catch (error) {
            setLoading(false);
            console.error("Error deleting User", error);
            alert("Failed to delete data. Please try again.");
        } finally {
        }
    };
    const Logout = async () => {
        setLoading(true)
        setTimeout(() => {
            localStorage.clear();
            sessionStorage.clear();
            setLoading(false)
            navigate("/course-plan");
        }, 1000);

    }
    const deleteCourse = async (id) => {
        if (!window.confirm("Are you sure you want to delete this course?")) return;
        try {
            setLoading(true);
            let config = {
                method: 'delete',
                url: BaseUrl + `/api/courses/${id}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            };

            await axios.request(config);
            alert("Course deleted successfully");
            fetchCourses(); // Refresh course list
        } catch (error) {
            setLoading(false);
            console.error("Error deleting course", error);
            alert("Failed to delete course. Please try again.");
        } finally {
        }
    };
    return (
        <Container className="course-list-container">
            <div className='btnDelete'>
                <Button
                    onClick={() => deleteUser()}
                    variant="outline-danger"
                >
                    Delete All Data
                </Button>
                <Button
                    onClick={() => Logout()}
                    variant="outline-danger"
                    className='btn1'
                >
                    Logout
                </Button>
            </div>
            <h2 className="course-title">Available Courses
            </h2>
            {loading ? (
                <div className="loader-container">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <Row className="row">
                    {courses?.length > 0 ? (
                        courses.map((course) => (
                            <Col key={course._id} md={9} sm={6} xs={12} className="course-card">
                                <Card className="shadow-sm border-0">
                                    <Card.Img variant="top" src={course.courseImage} className="course-image" />
                                    <Card.Body className="text-center">
                                        <Card.Title className="fw-bold">{course.name}</Card.Title>
                                        <Card.Text className="text-muted">📍 {course.venue}</Card.Text>
                                        <Button
                                            onClick={() => deleteCourse(course._id)}
                                            variant="outline-danger"
                                        >
                                            Delete
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    ) : (
                        <p className="text-center">No courses available.</p>
                    )}
                </Row>
            )}
        </Container>
    );
};
export default CourseList;