import React, { useState } from "react";
import "../styles/Contact.css";
import Privacy from "../assets/images/PrivacyPolicy.svg";
import Navbar from "../components/Navbar";
import Footer from "../pages/home/Footer"

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        company: "",
        email: "",
        contactNumber: "",
        jobTitle: "",
        helpText: "",
    });

    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Submitted:", formData);
        setSubmitted(true); // Hide form and show success message
    };

    return (
        <div>
            {/* Navbar */}


            {/* Hero Section */}
            <section className="hero-section">
                <Navbar />
                <div className="hero-content">
                    <h1>Your Future in Tech Starts Now</h1>
                    <h2><span>Innovate</span> | <span>Transform</span> | <span>Lead</span></h2>
                </div>
            </section>

            {/* Contact Section */}
            <div className="contact-container">
                {/* Left Section */}
                <div className="contact-info">
                    <h2>We're here to help you drive innovation in your business. Let's connect and bring your ideas to life!</h2>
                    <div className="contact-details">
                        <p>📧 hello@inovat8.com</p>
                        <p>📍 415 McCallum Street, Kingston, Ontario K7K 7L6 Canada</p>
                    </div>
                    <img src={Privacy} alt="Privacy SVG" />
                </div>

                {/* Right Section - Form or Success Message */}
                <div className="contact-form">
                    {submitted ? (
                        <div className="success-message">
                            <h2>You're connected with Inova8! 🎉</h2>
                            <p>Thank you for reaching out. We'll get back to you soon!</p>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <label>First and Last Name</label>
                            <input type="text" name="name" placeholder="John Robert" value={formData.name} onChange={handleChange} required />

                            <label>Company</label>
                            <input type="text" name="company" placeholder="Amazon" value={formData.company} onChange={handleChange} required />

                            <label>Email</label>
                            <input type="email" name="email" placeholder="Contact Email" value={formData.email} onChange={handleChange} required />

                            <label>Contact Number</label>
                            <input type="tel" name="contactNumber" placeholder="+1 123 456 789" value={formData.contactNumber} onChange={handleChange} required />

                            <label>Job Title</label>
                            <input type="text" name="jobTitle" placeholder="Title" value={formData.jobTitle} onChange={handleChange} />

                            <label>What do you need help with?</label>
                            <textarea name="helpText" placeholder="Describe your project or problem statement concisely in a few sentences." value={formData.helpText} onChange={handleChange} rows="4" required />

                            {/* CAPTCHA Section */}
                            <div className="captcha">
                                <input type="checkbox" required />
                                <label>I'm not a robot</label>
                            </div>

                            <button type="submit">Let's Connect</button>
                        </form>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ContactForm;
