import axios from 'axios';
import { BaseUrl } from './constants';

const API_URL = BaseUrl + "/api/"
export const registerUser = async (userData) => {
    return await axios.post(`${API_URL}/users/register`, userData);
};
let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: '',
    headers: {
        'Content-Type': 'application/json'
    },
    data: {}
};
export const loginUser = async (userData) => {
    config.url = API_URL + 'users/login'
    config.data = JSON.stringify(userData)
    return axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response))
            localStorage.setItem('token', response?.data?.token)
            localStorage.setItem('userId', response?.data?.user?._id)
            return true
        })
        .catch((error) => {
            return false
        });
};
export const getAllCourses = async () => {
    let token = localStorage.getItem('token')
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: API_URL + 'courses/user/token',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    };
    return axios.request(config)
        .then((response) => {
            console.log(JSON.stringify(response.data));
            return response.data
        })
        .catch((error) => {
            console.log(error);
        });
};
