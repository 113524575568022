// src/pages/Courses.js
import React, { useEffect } from 'react';
import CourseList from './CourseList';
import { useLocation, useNavigate } from 'react-router-dom';
const Courses = ({ token }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleBackButton = () => {
            navigate("/course-plan");
        };
        window.addEventListener("popstate", handleBackButton);
        return () => {
            window.removeEventListener("popstate", handleBackButton);
        };
    }, [navigate, location]);
    useEffect(() => {
        let token = localStorage.getItem('token')
        if (!token?.length) {
            navigate("/course-plan");
        }
    }, [])
    return (
        <div className='courses' >
            <CourseList token={token} />
        </div>
    );
};

export default Courses;