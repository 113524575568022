import React, { useState } from "react";
import { registerUser, loginUser } from "../../../api";
import { useNavigate } from "react-router-dom";
import "../styles/UserForm.css"; // Import CSS for styling
import Spinner from 'react-bootstrap/Spinner';

const UserForm = ({ isLogin }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState({
        email: "",
        password: "",
        firstname: "",
        lastname: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        try {
            if (isLogin) {
                let islogin = await loginUser(userData);
                if (islogin) {
                    navigate("/courses");
                } else {
                    alert("Login failed. Please check your email and password and try again.");
                }
            } else {
                await registerUser(userData);
                alert("User registered successfully!");
            }
        } catch (error) {
            console.error("Error submitting form", error);
            alert(error)
            // alert("An error occurred. Please try again.");
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div className="user-form-container">
            <h2>{isLogin ? "Login" : "Sign Up"}</h2>
            <form className="user-form" onSubmit={handleSubmit}>
                {!isLogin && (
                    <>
                        <input
                            type="text"
                            placeholder="First Name"
                            value={userData.firstname}
                            onChange={(e) => setUserData({ ...userData, firstname: e.target.value })}
                            required
                        />
                        <input
                            type="text"
                            placeholder="Last Name"
                            value={userData.lastname}
                            onChange={(e) => setUserData({ ...userData, lastname: e.target.value })}
                            required
                        />
                    </>
                )}
                <input
                    type="email"
                    placeholder="Email"
                    value={userData.email}
                    onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={userData.password}
                    onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                    required
                />
                <button type="submit" className="submit-button" disabled={loading}>
                    {loading ? <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> : isLogin ? "Login" : "Sign Up"}
                </button>
            </form>
        </div>
    );
};

export default UserForm;
