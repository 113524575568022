import React from "react";
import "../../styles/LandingPage.css";
import images from "../../assets/images";
import innova8 from "../../assets/images/Innovat8.svg";
const LandingPage = () => {
    return (
        <div className="landing-container">
            <div className="landing-content">
                <img
                    onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                    src={innova8}
                    alt="Inova8 Logo"
                    style={{ width: 400 }}
                />
                <div className="announcement-section">
                    <p className="bold" style={{ color: "white" }}>Inovat8 is Evolving!</p>
                    <p style={{ color: "white", fontSize: "5vh" }}>A Smarter, Faster Digital Experience is <span className="bold" style={{ color: "white" }}>Coming Soon.</span></p>
                </div>
                <p className="stay-tuned" style={{ color: "white" }}>Stay tuned!</p>
                <p className="landing-description" style={{ color: "white" }}>The future of digital transformation is just around the corner.</p>
                <div className="contact-section">
                    <p className="contact-sectionp" style={{ color: "white" }}>To know more, please contact us at:</p>
                    <p className="bold sectionp " style={{ color: "white", }}>Email: info@inovat8.com | Contact: +1 (519) 729-7356</p>
                </div>
            </div>
        </div>
    );
};
export default LandingPage;