import React from "react";

const PrivacyPolicy = () => {
    return (
        <div style={{ backgroundColor: '#fff', maxWidth: '100%' }}>

            <div style={{ backgroundColor: '#fff', padding: "40px", maxWidth: "900px", margin: "auto", fontFamily: "Arial, sans-serif", lineHeight: "1.6", color: "#333" }}>
                <h3 style={{ textAlign: "center", fontFamily: "Bauhaus2", color: "#000" }}>Privacy Policy for Course Plan App</h3>
                <p style={{ textAlign: "center", fontFamily: "Bauhaus2", fontStyle: "italic" }}>Effective Date: March 7, 2025 </p>
                <div style={{ background: "#f8f9fa", padding: "20px", borderRadius: "8px", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)" }}>
                    <p style={{ fontFamily: "Bauhaus2" }}>
                        At <strong style={{ fontFamily: "Bauhaus2" }}>Course Plan</strong> App, we take your privacy seriously and are committed to safeguarding the personal information you provide while using our app. This Privacy Policy explains how we collect, use, and protect your data in connection with our services.
                    </p>
                </div>
                <div style={{ marginTop: "20px" }}>
                    <h4 style={{ color: "#0056b3", fontFamily: "Bauhaus2" }}>1. Information We Collect</h4>
                    <p style={{ fontFamily: "Bauhaus2" }}>
                        To enhance your experience on Course Plan App, we may request access to your device’s camera for the following purposes:
                        <br />Camera Access:<br />
                        <ul style={{ fontFamily: "Bauhaus2" }}>Our app requests access to your device’s camera to allow users to capture and upload images directly. These images may include:</ul>
                        <ul><li style={{ fontFamily: "Bauhaus2" }}>Course-related images: Photos of course diagram and schematics.</li></ul>
                        <ul><li style={{ fontFamily: "Bauhaus2" }}>Profile pictures: Upload or update your profile image to personalize your account.</li></ul>
                    </p>
                </div>

                <div style={{ marginTop: "20px" }}>
                    <h4 style={{ color: "#0056b3", }}>2. How We Use Your Information</h4>
                    <p style={{ fontFamily: "Bauhaus2" }}>The images you capture through the camera feature are used for the following purposes:
                        <ul><li style={{ fontFamily: "Bauhaus2" }}>Uploading Course-Related Images: Images related to course diagram and schematics. These images may help instructors and/or other riders provide feedback and support.</li></ul>
                        <ul><li style={{ fontFamily: "Bauhaus2" }}>Profile Picture Updates: You may use the camera to capture a profile image to represent yourself within the Course Plan App community.</li></ul></p>
                </div>
                <div style={{ marginTop: "20px" }}>
                    <h4 style={{ color: "#0056b3" }}>3. Data Security</h4>
                    <p style={{ fontFamily: "Bauhaus2" }}>We prioritize the security of your data. Any images you upload or capture are stored securely within the app. We take appropriate technical and organizational measures to protect your data from unauthorized access or disclosure. Your images and data are only used within the app for the purposes described above.</p>
                </div>
                <div style={{ marginTop: "20px" }}>
                    <h4 style={{ color: "#0056b3" }}>4. Sharing of Information</h4>
                    <p style={{ fontFamily: "Bauhaus2" }}>We respect your privacy and will not share the images you capture or upload with any third parties without your explicit consent. We will not sell or rent your images to anyone. The only exceptions to this are if we are legally required to share data or if you consent to share your information with others (such as instructors, other riders, or specific users of the app).</p>
                </div>

                <div style={{ marginTop: "20px" }}>
                    <h4 style={{ color: "#0056b3" }}>5. Your Consent</h4>
                    <p style={{ fontFamily: "Bauhaus2" }}>By enabling camera access on your device, you consent to the collection, use, and storage of images as outlined in this Privacy Policy. You can withdraw your consent at any time by disabling camera access through your device settings. However, this may limit some features of the app, such as uploading course-related content or updating your profile picture.</p>
                </div>

                <div style={{ marginTop: "20px" }}>
                    <h4 style={{ color: "#0056b3" }}>6. Changes to This Privacy Policy</h4>
                    <p style={{ fontFamily: "Bauhaus2" }}>Course Plan App may update this Privacy Policy periodically. If we make any significant changes, we will update the “Effective Date” at the top of this page. We encourage you to review this Privacy Policy regularly to stay informed about how we are protecting your information.</p>
                </div>

                <div style={{ marginTop: "20px" }}>
                    <h4 style={{ color: "#0056b3" }}>7. Contact Us</h4>
                    <p style={{ fontFamily: "Bauhaus2" }}>If you have any questions or concerns about this Privacy Policy or how we handle your data, please contact us at
                        <strong><ul><li style={{ fontFamily: "Bauhaus2" }}>
                            <a href="mailto:courseplan.app@inovat8.com">courseplan.app@inovat8.com</a></li></ul>
                            <ul><li style={{ fontFamily: "Bauhaus2" }}>
                                <a href="mailto:courseplan.app@inovat8.com">courseplanapp@gmail.com</a></li></ul></strong>
                        By using the Course Plan App, you acknowledge that you have read and understood this Privacy policy.</p>
                </div>
            </div>
        </div >
    );
};

export default PrivacyPolicy;
